import Pagination from '@/components/Pagination/Pagination.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import QueryMixin from '@/mixins/query.mixin'
import SearchLocations from '@/graphql/SearchLocations.gql'
import EnableLocation from '@/graphql/EnableLocation.gql'
import DisableLocation from '@/graphql/DisableLocation.gql'

export default {
  mixins: [GraphQlMixin, QueryMixin],
  components: {
    Pagination
  },
  data() {
    return {
      locations: [],
      locationsCount: 0,
      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200] },
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['region', 'name'],
        sortDesc: [false, false],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      headers: [
        { text: 'Enabled', value: 'enabled' },
        { text: 'Name', value: 'name' },
        { text: 'Region', value: 'region' },
        { text: 'Address', value: 'address' }
      ],
      search: ''
    }
  },
  watch: {
    // Everytime the route changes, reset search
    $route(to, from) {
      if (to.path !== from.path) {
        this.search = ''
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== from.path) {
      this.search = ''
    }
    next()
  },
  computed: {
    totalPage() {
      return this.options.itemsPerPage === -1 || this.locationsCount === 0
        ? 1
        : Math.ceil(this.locationsCount / this.options.itemsPerPage)
    }
  },
  methods: {
    async onChange(location) {
      let mutation
      if (location.enabled) {
        mutation = EnableLocation
      } else {
        mutation = DisableLocation
      }
      try {
        location.loading = true
        await this.$apollo.mutate({
          mutation,
          variables: {
            input: {
              locationId: location.id
            }
          }
        })
      } catch (error) {
        location.enabled = !location.enabled
        this.graphQLOnError(`Failed to update location. ${error.toString()}`)
      }
      location.loading = false
    }
  },
  apollo: {
    locations: {
      query: SearchLocations,
      variables() {
        return {
          q: this.search,
          take:
            this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
          skip:
            this.options.itemsPerPage === -1
              ? 0
              : this.options.itemsPerPage * (this.options.page - 1),
          orderBy: this.getOrderBy(),
          where: []
        }
      },
      result({ data, error }) {
        if (data && data.locations) {
          this.locations = data.locations.items
          this.locationsCount = data.locations.totalCount
        } else if (error) {
          this.graphQLOnError(
            `Failed to get list of locations. ${error.toString()}`
          )
        }
      }
    }
  },
  created() {
    if (!this.ability.can('read', 'Location')) {
      this.$router.push('/')
    }
    this.search = ''
  }
}
